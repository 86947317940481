import { Link } from "react-router-dom";
import { LinkLogo, Header, Navigation, IconInsta, IconFacebook, WrapperSocial, LinkSocial } from "./AppBar.styled";
import MathlabLogo from '../assets/images/light.svg';

export const AppBar = () => {
    return (
        <Header>
            <Navigation>

                <LinkLogo component={Link} to="/" >
                    <img src={MathlabLogo} width="24" height="24" alt="Logo Mathlab" />
                    <span>Home</span>
                </LinkLogo>
                
                <WrapperSocial>

                    <LinkSocial to="https://www.instagram.com/mathlab.it/" target="_blank" rel="noopener noreferrer" aria-label="instagram">                        
                        <IconInsta />
                    </LinkSocial>
                    
                    <LinkSocial to="https://www.facebook.com/profile.php?id=61554178910841" target="_blank" rel="noopener noreferrer" aria-label="facebook">
                        <IconFacebook />
                    </LinkSocial>
                
                </WrapperSocial>

            </Navigation>
        </Header>
    );
};