import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { Copyright, Follow, FooterStyled, HelpDescription, IconFacebook, IconInsta, LinkEmail, LinkSocial, WrapperMail,WrapperSocial, WrapperMailSocial } from "./Footer.styled";

export const Footer = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <FooterStyled>            
         
                
                <WrapperMailSocial>

                    <WrapperMail>
                        <HelpDescription>Contattaci:</HelpDescription>
                        <LinkEmail href="mailto:mathlab.it@gmail.com">
                            mathlab.it@gmail.com
                        </LinkEmail>
                    </WrapperMail> 

                    <WrapperSocial>

                        {!mobile && <Follow>Seguici: </Follow>}

                        <LinkSocial to="https://www.instagram.com/mathlab.it/" target="_blank" rel="noopener noreferrer" aria-label="instagram">
                            <IconInsta />
                        </LinkSocial>

                        <LinkSocial to="https://www.facebook.com/profile.php?id=61554178910841" target="_blank" rel="noopener noreferrer" aria-label="facebook">
                            <IconFacebook />
                        </LinkSocial>

                    </WrapperSocial>
                    
                </WrapperMailSocial>

            <Copyright>&copy; 2023 Kateryna Gordiienko</Copyright>
            
        </FooterStyled>
    )
}